import { createContext, useEffect, useState } from "react";
import FullPageLoader from "../components/full-page-loader";
import { getUserProfile } from "./getUserProfile";
import { useGetState } from "./useGetState";

export const UserProfileContext = createContext({});

export const UserProfileContextProvider = ({ children }) => {
  const [state, setState] = useState({
    signin: true,
    forgotPassword: false,
    resetPassword: false,
  });
  const [firebaseUser, setFirebaseUser] = useState({});
  const token = sessionStorage.getItem("#f8WEB#");
  const { state: globalState } = useGetState() || {};
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (!token) {
      setLoading(false);
      return;
    }
    dispatch({ ready: false });

    const getUsersAndStore = async () => {
      await getUserProfile(
        setState,
        sessionStorage.getItem("acc_type") || "trader"
      );
      setLoading(false);
    };

    getUsersAndStore();
  }, [token, globalState]);

  const dispatch = (incoming) => {
    setState((prev) => ({ ...prev, ...incoming }));
  };
  return (
    <UserProfileContext.Provider value={{ dispatch, state, firebaseUser }}>
      {loading ? <FullPageLoader /> : children}
    </UserProfileContext.Provider>
  );
};
