import { Flex, Spinner } from "@chakra-ui/react";

export default function FullPageLoader({ h }) {
  return (
    <Flex
      overflow={"hidden"}
      justifyContent="center"
      alignItems="center"
      height={h ? h : "100vh"}
      width="100%"
    >
      <Spinner thickness="4px" speed="0.65s" color="#E54242" size="xl" />
    </Flex>
  );
}
