import "./App.css";
import { lazy, Suspense, useEffect, useState } from "react";
import { UserProfileContextProvider } from "./context/UserContext";
import "firebase/compat/firestore";
import { ChatContextProvider } from "./context/ChatContext";
import "animate.css";

const UnAuthenticatedRoutes = lazy(() =>
  import("./components/UnAuthenticatedRoutes")
);
const AuthenticatedRoutes = lazy(() =>
  import("./components/AuthenticatedRoutes")
);

function App() {
  const [authorized, setAuthorized] = useState(false);
  let token =
    sessionStorage.getItem("#f8WEB#") &&
    sessionStorage.getItem("#f8WEB-firebase-success#");

  useEffect(() => {
    if (token) {
      setAuthorized(true);
    } else {
      setAuthorized(false);
    }
  }, [token]);

  return (
    <div className="App">
      <UserProfileContextProvider>
        <ChatContextProvider>
          <Suspense fallback={<p></p>}>
            {authorized ? <AuthenticatedRoutes /> : <UnAuthenticatedRoutes />}
          </Suspense>
        </ChatContextProvider>
      </UserProfileContextProvider>
    </div>
  );
}

export default App;
