import { useContext, useEffect, useState } from "react";
import { ChatContext } from "./ChatContext";
import { UserProfileContext } from "./UserContext";

export const useGetState = () => {
  const { state, dispatch } = useContext(UserProfileContext);
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    setUserInfo(state);
  }, [state?.userInfo, dispatch]);

  return { state: userInfo, dispatch };
};

export const useGetChatState = () => {
  const { data, dispatch } = useContext(ChatContext);
  return { data, dispatch };
};
