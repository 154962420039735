import http, { AUTH_ROUTES } from "../service/api";

export const getUserProfile = async (setState, account_type) => {
  let loading = true;
  try {
    loading = true;
    const data = await http.get(AUTH_ROUTES.GET_ACCOUNT);
    loading = false;
    setState &&
      setState((prev) => ({
        ...prev,
        loading,
        userInfo: data?.data,
      }));

    return data?.data;
  } catch (e) {
    loading = false;
  }
};
